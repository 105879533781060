import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'sign-in',
    redirect: '/sign-in',
  },
  {
		path: '/sign-in',
		name: 'Sign-In',
		component: () => import('../views/Signin/Sign-In.vue'),
	},
  {
		path: '/user',
		name: 'user',
	
		meta:{requiresAuth:true},
		component: () => import('../views/User/user.vue'),

   
	},
	{
		path: '/user/create',
		name: 'usercreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/User/usercreate.vue'),
	},
	{
        name: 'Useredit',
        path: '/user/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/User/useredit.vue'),

	},
	{
		path: '/role',
		name: 'role',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Role/role.vue'),
	},
	{
		path: '/role/create',
		name: 'rolecreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Role/create.vue'),
	},
	{
		path: '/permission',
		name: 'permission',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Permission/permission.vue'),
	},
  {
		path: '/profile_list',
		name: 'profile_list',
		meta:{requiresAuth:true},

		component: () => import('../views/profile/list.vue'),
	},

	{
        name: 'edit-profile',
        path: '/editprofile',
		meta:{requiresAuth:true},

		component: () => import('../views/profile/edit.vue'),

	},
	{
        name: 'change_password',
        path: '/change_password',
		meta:{requiresAuth:true},

		component: () => import('../views/profile/changepassword.vue'),

	},
	{
		path: '/status_list',
		name: 'status_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Status/list.vue'),

   
	},
	{
		path: '/status/create',
		name: 'statuscreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Status/create.vue'),
	},
	{
        name: 'statusedit',
        path: '/status/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Status/edit.vue'),

	},
	{
		path: '/order_status_list',
		name: 'order_status_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Order_Status/list.vue'),

   
	},
	{
		path: '/order_status/create',
		name: 'ordstatuscreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Order_Status/create.vue'),
	},
	{
        name: 'ordstatusedit',
        path: '/order_status/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Order_Status/edit.vue'),

	},

	{
		path: '/Payment_Status_list',
		name: 'Payment_Status_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Payment_Status/list.vue'),

   
	},
	{
		path: '/Payment_Status/create',
		name: 'paymentstscreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Payment_Status/create.vue'),
	},
	{
        name: 'paymentstsedit',
        path: '/Payment_Status/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Payment_Status/edit.vue'),

	},
	{
		path: '/Challenge_Status_list',
		name: 'Challenge_Status_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Challenge_Status/list.vue'),

   
	},
	{
		path: '/Challenge_Status/create',
		name: 'Challengestscreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Challenge_Status/create.vue'),
	},
	{
        name: 'Challengestsedit',
        path: '/Challenge_Status/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Challenge_Status/edit.vue'),

	},
	{
		path: '/mission_Status_list',
		name: 'mission_Status_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Mission_Status/list.vue'),

   
	},
	{
		path: '/mission_Status/create',
		name: 'missionStscreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Mission_Status/create.vue'),
	},
	{
        name: 'missionStsedit',
        path: '/mission_Status/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Mission_Status/edit.vue'),

	},
	{
		path: '/Payment_Mode_list',
		name: 'Payment_Mode_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Payment_Mode/list.vue'),

   
	},
	{
		path: '/Payment_Mode/create',
		name: 'Paymentmodecreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Payment_Mode/create.vue'),
	},
	{
        name: 'Paymentmodeedit',
        path: '/Payment_Mode/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Payment_Mode/edit.vue'),

	},
	{
		path: '/Symptom_list',
		name: 'Symptom_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Symptom/list.vue'),

   
	},
	{
		path: '/Symptom/create',
		name: 'Symptomcreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Symptom/create.vue'),
	},
	{
        name: 'Symptomedit',
        path: '/Symptom/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Symptom/edit.vue'),

	},
	{
		path: '/Playing_Position_list',
		name: 'Playing_Position_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Playing_Position/list.vue'),

   
	},
	{
		path: '/Playing_Position/create',
		name: 'PlayingPositioncreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Playing_Position/create.vue'),
	},
	{
        name: 'PlayingPositionedit',
        path: '/Playing_Position/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Playing_Position/edit.vue'),

	},
	{
		path: '/Skill_list',
		name: 'Skill_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Skill/list.vue'),

   
	},
	{
		path: '/Skill/create',
		name: 'Skillcreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Skill/create.vue'),
	},
	{
        name: 'Skilledit',
        path: '/Skill/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Skill/edit.vue'),

	},
	{
		path: '/Level_list',
		name: 'Level_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Level/list.vue'),

   
	},
	{
		path: '/Level/create',
		name: 'Levelcreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Level/create.vue'),
	},
	{
        name: 'Leveledit',
        path: '/Level/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Level/edit.vue'),

	},
	{
		path: '/Challenge_Level_list',
		name: 'Challenge_Level_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Challenge_Level/list.vue'),

   
	},
	{
		path: '/Challenge_Level/create',
		name: 'ChallengeLevelcreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Challenge_Level/create.vue'),
	},
	{
        name: 'ChallengeLeveledit',
        path: '/Challenge_Level/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Challenge_Level/edit.vue'),

	},
	{
		path: '/mission_type_list',
		name: 'mission_type_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Mission_Type/list.vue'),

   
	},
	{
		path: '/mission_type/create',
		name: 'missiontypecreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Mission_Type/create.vue'),
	},
	{
        name: 'missiontypeedit',
        path: '/mission_type/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Mission_Type/edit.vue'),

	},
	{
		path: '/age_group_list',
		name: 'age_group_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Age_Group/list.vue'),

   
	},
	{
		path: '/age_group/create',
		name: 'agegroupcreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Age_Group/create.vue'),
	},
	{
        name: 'agegroupedit',
        path: '/age_group/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Age_Group/edit.vue'),

	},

	{
		path: '/Stage_list',
		name: 'Stage_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Stage/list.vue'),

   
	},
	{
		path: '/Stage/create',
		name: 'Stagecreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Stage/create.vue'),
	},
	{
        name: 'Stageedit',
        path: '/Stage/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Stage/edit.vue'),

	},
	{
		path: '/Injury_list',
		name: 'Injury_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Injury/list.vue'),

   
	},
	{
		path: '/Injury/create',
		name: 'Injurycreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Injury/create.vue'),
	},
	{
        name: 'Injuryedit',
        path: '/Injury/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Injury/edit.vue'),

	},
	{
		path: '/Concussion_list',
		name: 'Concussion_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Concussion/list.vue'),

   
	},
	{
		path: '/Concussion/create',
		name: 'Concussioncreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Concussion/create.vue'),
	},
	{
        name: 'Concussionedit',
        path: '/Concussion/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Concussion/edit.vue'),

	},
	{
		path: '/Logtime_list',
		name: 'Logtime_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Logtime/list.vue'),

   
	},
	{
		path: '/Logtime/create',
		name: 'Logtimecreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Logtime/create.vue'),
	},
	{
        name: 'Logtimeedit',
        path: '/Logtime/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Logtime/edit.vue'),

	},
	{
		path: '/Club_list',
		name: 'Club_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Club/list.vue'),

   
	},
	{
		path: '/Club/create',
		name: 'Clubcreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Club/create.vue'),
	},
	{
        name: 'Clubedit',
        path: '/Club/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Club/edit.vue'),

	},
	{
		path: '/Experience_list',
		name: 'Experience_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Experience/list.vue'),

   
	},
	{
		path: '/Experience/create',
		name: 'Experiencecreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Experience/create.vue'),
	},
	{
        name: 'Experienceedit',
        path: '/Experience/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Experience/edit.vue'),

	},

	{
		path: '/Mission_list',
		name: 'Mission_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Mission/list.vue'),

   
	},
	{
		path: '/Mission/create',
		name: 'Missioncreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Mission/create.vue'),
	},
	{
        name: 'Missionedit',
        path: '/Mission/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Mission/edit.vue'),

	},

	{
		path: '/Skill_list',
		name: 'Skill_list',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Skill/list.vue'),

   
	},
	{
		path: '/Skill/create',
		name: 'Skillcreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Skill/create.vue'),
	},
	{
        name: 'Skilledit',
        path: '/Skill/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Skill/edit.vue'),

	},
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to,from,next)=>{
	if(to.meta.requiresAuth){
		const token = localStorage.getItem('token');
if(token){
	next()

}else{
	next(
		'/sign-in'
	);
}
	}else{
		next()
	}
})
export default router
